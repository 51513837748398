/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Canvas } from '@react-three/fiber';
import { CanvasContents } from './canvas-contents';
import { RolodexSectionFragment } from 'src/api/entities/sections/rolodex/types';
import { Text } from 'src/components/core/text';
import { blurFadeInTopAnimation } from 'src/core/constants/motion';
import { media } from 'src/styles/media';
import { motion, useInView, useMotionValueEvent, useScroll } from 'framer-motion';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import React, { useMemo, useRef, useState } from 'react';
import clamp from 'lodash/clamp';
import isNumber from 'lodash/isNumber';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `Section` styled component.
 */

const Section = styled.section`
  background-color: var(--color-neutral105);
  position: relative;

  &[data-visible='false'] {
    opacity: 0;
    pointer-events: none;
    user-select: none;
    visibility: hidden;
  }
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  height: 250vh;
`;

/**
 * `FixedContent` styled component.
 */

const FixedContent = styled.div`
  --rolodex-gradient-color-left: 49 123 65;
  --rolodex-gradient-color-right: 26 87 182;
  --rolodex-padding-top: calc(
    var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3
  );

  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  opacity: var(--rolodex-opacity, 1);
  padding: var(--rolodex-padding-top) 0 24px;
  position: fixed;
  transform: translateY(var(--rolodex-offset, 0));
  width: 100vw;

  @media (min-height: 1200px) {
    justify-content: center;
    padding-top: 0;
  }

  ${media.max.ms`
    @media (min-height: 800px) {
      justify-content: center;
      padding-top: calc(var(--rolodex-padding-top) / 2);
    }
  `}

  &::before,
  &::after {
    content: '';
    height: 200%;
    left: 0;
    position: absolute;
    top: 0;
    width: 200%;
  }

  &::before {
    background: radial-gradient(
      circle,
      rgb(var(--rolodex-gradient-color-left) / 0.5) 1%,
      rgb(var(--rolodex-gradient-color-left) / 0) 40%
    );
    transform: translate(-50%, -12%) scale(0.5, 0.65);
  }

  &::after {
    background: radial-gradient(
      circle,
      rgb(var(--rolodex-gradient-color-right) / 0.5) 0%,
      rgb(var(--rolodex-gradient-color-right) / 0) 40%
    );
    transform: translate(5%, -12%) scale(0.65, 0.8);
  }

  ${media.min.md`
    &::before {
      background: radial-gradient(
        circle,
        rgb(var(--rolodex-gradient-color-left) / 0.5) 0%,
        rgb(var(--rolodex-gradient-color-left) / 0.1) 35%,
        rgb(var(--rolodex-gradient-color-left) / 0) 50%
      );
      transform: translate(-50%, -40%) scale(0.8);
    }

    &::after {
      background: radial-gradient(
        circle,
        rgb(var(--rolodex-gradient-color-right) / 0.5) 0%,
        rgb(var(--rolodex-gradient-color-right) / 0) 50%
      );
      transform: translate(2%) scale(1);
    }
  `}
`;

/**
 * `CanvasParent` styled component.
 */

const CanvasParent = styled(motion.div)`
  aspect-ratio: 6/7;
  max-height: 60vh;
  max-width: 80vw;
  position: relative;
  width: 100vw;

  ${media.min.ms`
    margin-top: max(-20vh, -200px);
    top: min(20vh, 200px);
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled(motion.div)`
  display: grid;
  grid-template-areas: 'pretitle' 'title';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  max-width: 600px;
  text-align: center;
`;

/**
 * `Tag` styled component.
 */

const Tag = styled(Text)`
  color: var(--color-primary);
  grid-area: pretitle;
  opacity: 0;
  transition: opacity var(--transition-default);

  &[data-active='true'] {
    opacity: 1;
  }
`;

/**
 * `ProgressBar` styled component.
 */

const ProgressBar = styled.div`
  background-color: var(--color-neutral80);
  border-radius: var(--border-radius);
  display: block;
  height: 4px;
  margin: min(max(24px, 3vh), 40px) auto min(max(32px, 4vh), 60px);
  max-width: 9rem;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    background-color: var(--color-neutral0);
    content: '';
    inset: 0;
    position: absolute;
    transform: scaleX(var(--progress-value, 0));
    transform-origin: left center;
  }

  ${media.min.ms`
    margin: min(max(24px, 3vh), 40px) auto min(max(32px, 6vh), 80px);
    max-width: 18rem;
  `}

  [data-no-vertical-space='true'] & {
    margin: 24px auto 32px;
  }
`;

/**
 * `BottomContent` styled component.
 */

const BottomContent = styled(motion.div)`
  align-items: center;
  display: flex;
  height: max-content;
  inset: auto 0 0;
  justify-content: center;
  overflow: hidden;
  padding: 12vh 0 24px;
  position: sticky;

  &::before {
    background: radial-gradient(ellipse 80% 100% at center bottom, var(--color-background) 60%, transparent 100%);
    content: '';
    inset: 0 -50%;
    position: absolute;

    ${media.min.ms`
      background: radial-gradient(ellipse 60% 100% at center bottom, var(--color-background) 75%, transparent 100%);
    `}
  }

  ${media.min.ms`
    padding: 15vh 0 72px;
  `}
`;

/**
 * Export `Rolodex` component.
 */

export function Rolodex({ anchorId, cta, items, title }: RolodexSectionFragment) {
  const [activeIndex, setActiveIndex] = useState(0);
  const { scrollY } = useScroll();
  const animationId = useRef<number | null>(null);
  const bottomContentRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const isBottomContentInView = useInView(bottomContentRef);
  const isMobile = !useBreakpoint('ms');
  const hasEnoughVerticalHeight = useBreakpoint(820, undefined, 'height');
  const sectionRef = useRef<HTMLDivElement>(null);
  const timelineControl = useRef(0);
  const textVariant = hasEnoughVerticalHeight ? 'heading1' : 'heading2';
  const cards = useMemo(
    () => ({
      count: items.length + 1,
      scrollPercentage: 1 / (items.length + 1)
    }),
    [items]
  );

  useMotionValueEvent(scrollY, 'change', (latest: number) => {
    if (isNumber(animationId.current)) {
      cancelAnimationFrame(animationId.current);
    }

    function updatePosition() {
      if (!contentRef.current || !sectionRef.current) {
        return;
      }

      const section = sectionRef.current?.getBoundingClientRect();
      const maxScroll = section.height - window.innerHeight * cards.scrollPercentage;
      const isCanvasInView = latest <= maxScroll;
      const yieldedScrollPosition = section.height - window.innerHeight;

      // Bail out of rendering if the canvas is not in view.
      if (!isCanvasInView) {
        return;
      }

      // This pin point is the trigger to start the animations.
      const pinPoint = 1 - cards.scrollPercentage;

      // This is the point where the scroll of the page will continue
      const yieldScrollPoint = yieldedScrollPosition / maxScroll;

      // This is the current scroll position, from 0 to 1.
      const scrollPosition = latest / maxScroll;

      // Truncate the progress value to fill before the end.
      const progress = latest >= maxScroll ? 1 : scrollPosition;

      // This is the active card index.
      const index = Math.min(Math.floor(scrollPosition / cards.scrollPercentage), items.length - 1);
      const pastPinPoint = scrollPosition > pinPoint;
      const offset = pastPinPoint ? (scrollPosition - pinPoint) * -150 : 0;
      const opacity = pastPinPoint ? clamp(1 - (scrollPosition - pinPoint) * cards.count, 0, 1).toFixed(2) : 1;
      const animationOpacity =
        scrollPosition > yieldScrollPoint
          ? ((scrollPosition - pinPoint) / (yieldScrollPoint - pinPoint)).toFixed(2)
          : 1;

      timelineControl.current = scrollPosition;
      sectionRef.current.style.setProperty('--progress-value', `${clamp(progress / pinPoint, 0, 1)}`);
      sectionRef.current.style.setProperty('--rolodex-offset', `${offset}%`);
      sectionRef.current.style.setProperty('--rolodex-animation-opacity', `${animationOpacity}`);
      sectionRef.current.style.setProperty('--rolodex-opacity', `${opacity}`);

      if (index !== activeIndex) {
        setActiveIndex(index);
      }
    }

    animationId.current = requestAnimationFrame(updatePosition);
  });

  return (
    <Section
      data-no-vertical-space={!hasEnoughVerticalHeight}
      data-theme={'dark'}
      data-visible={isBottomContentInView}
      ref={sectionRef}
      {...(anchorId && { id: anchorId })}
    >
      <ContentWrapper aria-hidden={!isBottomContentInView} ref={contentRef}>
        <FixedContent>
          <Grid {...blurFadeInTopAnimation(0, 20)}>
            {items.map(({ pretitle }, index) => (
              <Tag data-active={activeIndex === index} key={index} variant={textVariant}>
                {pretitle}
              </Tag>
            ))}

            <Text color={'neutral0'} variant={textVariant}>
              {title}
            </Text>

            <ProgressBar />
          </Grid>

          <CanvasParent {...blurFadeInTopAnimation(0.2)}>
            <Canvas
              camera={{ fov: 52, position: [0, 0, 5.7], zoom: 1.75 }}
              flat
              style={{ opacity: 'var(--rolodex-animation-opacity, 1)' }}
            >
              <CanvasContents cheaperRender={isMobile} items={items} scrollPosition={timelineControl} />
            </Canvas>
          </CanvasParent>
        </FixedContent>
      </ContentWrapper>

      <BottomContent ref={bottomContentRef} {...blurFadeInTopAnimation(0.4, 30)}>
        {cta?.label && cta?.href && (
          <Button
            {...omit(cta, 'label')}
            aria-label={cta.label}
            hasLinkIcon
            size={'large'}
            style={{ opacity: 'calc(var(--rolodex-opacity, 1) * 2)' }}
            variant={'primary'}
          >
            {cta.label}
          </Button>
        )}
      </BottomContent>
    </Section>
  );
}
